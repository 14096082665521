import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import SEO from "../seo"
import Footer from "../Footer"

const LegalPage = (props) => {

  const {
    seo,
    heroTitle, 
    lastUpdated,
    children
  } = props;

  const [screenWidth, setScreenWidth] = useState('');

  useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  };

  return (
    <div>
      <SEO title={seo} description={heroTitle} />
      <div className="container d-flex justify-content-between py-3 mb-5">
        <Link to="/"><img alt="HiStartup Logo" src={require('../../images/Primary.svg')}></img></Link>
      </div>
      <br></br>
      <div className="container mt-0 mt-md-4 mb-5 text-center">
        {screenWidth > 767 ?
          <h1 className="mx-auto mb-3">{heroTitle}</h1>
          :
          <h3 className="mx-auto mb-3">{heroTitle}</h3>
        }
        <p>Last updated {lastUpdated}</p>
        <br></br>
      </div>
      <br></br>
      <hr></hr>
      {children}
      <Footer />
    </div>
  )
}

export default LegalPage;